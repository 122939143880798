@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Bellefair');

@font-face {
  font-family: 'Satoshi-Variable';
  src: url('../public/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('../public/fonts/Satoshi-Variable.woff') format('woff'),
       url('../public/fonts/Satoshi-Variable.ttf') format('truetype');
       font-weight: 300 900;
       font-display: swap;
       font-style: normal;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi-Variable", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1b2021;
}

